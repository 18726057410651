import { template as template_44115cf8ad994208a85bd39184b8b96a } from "@ember/template-compiler";
const FKControlMenuContainer = template_44115cf8ad994208a85bd39184b8b96a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
