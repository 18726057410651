import { template as template_b604d6da3b4c43059d462e4f02f0ec17 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_b604d6da3b4c43059d462e4f02f0ec17(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
