import { template as template_94cdba1118674c03aa775d4d6c62e06a } from "@ember/template-compiler";
const FKLabel = template_94cdba1118674c03aa775d4d6c62e06a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
